import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { DoctorSettlementModel } from "src/app/finance/doctor-finance-management/doctor-finance.model";

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.css']
})
export class DetailsDialogComponent implements OnInit {

  type: string = "success";
  title: string = "عنوان";
  text: string = "متن";
  buttons: any[] = [];
  checks: any[] = [];
  textType: string = 'text';
  doctorSettlement: DoctorSettlementModel;

  pdfData: DoctorSettlementModel;
  isPrintReady = false;
  isLoadingFactor = false;

  constructor(
      public dialog: MatDialogRef<DetailsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.type = this.data?.type;
    this.title = this.data?.title;
    this.text = this.data?.text;
    this.buttons = this.data?.buttons;
    this.doctorSettlement = this.data?.settlement;
  }

  cancel() {
    this.dialog.close(false);
  }

  confirm() {
    this.isLoadingFactor = true;
    this.isPrintReady = true;
    const printButton = document.getElementById('ngx-print-button') as HTMLElement;
    this.pdfData = this.doctorSettlement;
    setTimeout(() => {
      if (printButton) {
        printButton.click();
      }
      this.isLoadingFactor = false;
    }, 300);

  }

  prepareAndPrint(): void {
    this.isLoadingFactor = true;
    this.isPrintReady = true;
    const printButton = document.getElementById('ngx-print-button') as HTMLElement;
    this.pdfData = this.doctorSettlement;
    setTimeout(() => {
      if (printButton) {
        printButton.click();
      }
      this.isLoadingFactor = false;
    }, 300);
  }

}
