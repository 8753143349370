import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionServicePipe'
})
export class TransactionServicePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'مشاوره';
      case 1:
        return 'لغو مشاوره توسط پزشک';
      case 2:
        return 'لغو مشاوره توسط بیمار';
      case 3:
        return 'لغو مشاوره به دلیل مشکل فنی';
      case 4:
        return 'شارژ کیف پول به صورت اعتبار (پیش دریافت)';
      case 5:
        return 'عودت وجه از کیف پول به حساب بیمار';
      case 6:
        return 'لغو مشاوره به دلیل عدم حضور پزشک و بیمار';
      case 7:
        return 'شارژ ناموفق کیف پول بیمار';
      default:
        return 'نامشخص';
    }
  }

}
