<form style="width: 20em" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row form-row">
        <div class="col-12"  *ngFor="let field of formFields">
            <div *ngIf="field.type === 'date'">
                <app-date-picker
                        [label]="field.label"
                        [control]="form.get(field.name)"
                        [placeHolder]="field.title"
                        [isMat]="true"
                        [value]="form.get(field.name)?.value"
                        (valueChange)="onDateChange($event, field.name)"
                ></app-date-picker>
            </div>
            <div *ngIf="field.type === 'select'">
                <app-select-mat
                        [label]="field.label"
                        [control]="form.get(field.name)"
                        [placeHolder]="field.title"
                        [selectList]="field.options"
                ></app-select-mat>
            </div>
            <div *ngIf="field.type === 'input'">
                <app-input-mat
                        type="text"
                        [label]="field.label"
                        [control]="form.get(field.name)"
                        [placeHolder]="field.title">
                </app-input-mat>
            </div>
        </div>

        <hr class="w-100 d-flex my-1">
        <div class="form-group d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-light mt-4" (click)="resetForm()">
                <i class="fa fa-filter"></i>
                حذف همه فیلترها
            </button>
            &nbsp;
            <button class="btn btn-sm btn-primary mt-4" type="submit">
                اعمال فیلترها
            </button>
        </div>
    </div>
</form>
