<div class="p-4 border rounded-md shadow-md">
    <h2 class="text-lg font-bold mb-4">{{ title }}</h2>
    <div class="flex flex-col gap-4">
        <div *ngFor="let row of rows" class="flex list-two-row border rounded-md">
            <div *ngFor="let item of row" class="flex w-1/2 p-3">
                <span class="font-medium">{{ item.key }}:</span>
                <!-- Display value directly if it's a primitive -->
                <span class="text-gray-700" *ngIf="!isArray(item.value) && !isObject(item.value)">
          {{ item.value }}
        </span>

                <!-- Display nested objects (e.g., users) -->
                <ul *ngIf="isArray(item.value)" class="text-gray-700 list-disc ml-4">
                    <li *ngFor="let subItem of item.value">
                        <!-- Dynamically handle nested objects -->
                        <div *ngFor="let subKey of objectKeys(subItem)">
                            <span class="font-medium">{{ subKey }}:</span>
                            <span class="text-gray-700">{{ subItem[subKey] }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
