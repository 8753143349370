<div class="bg-white overflow-auto rounded-sm d-flex flex-column" dir="rtl">

    <div id="print-section">
        <div mat-dialog-content
             class="py-3 px-3 bold d-flex align-items-baseline justify-content-between" [ngClass]="type === 'error' ? 'error-dialog' : 'success-dialog'">
            <span class="mx-3">{{ title }}</span>
<!--            <button class="d-flex align-items-center justify-content-center mx-3 btn-red-link" (click)="cancel()">-->
<!--                <i class="fa fa-times" aria-hidden="true"></i>-->
<!--            </button>-->
        </div>
        <div class="flex-grow-0 mx-3 pt-3 pb-4 px-3">

            <div class="my-3 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div ngSwitch="{{ doctorSettlement?.status }}" class="main-color bold">
                        <div *ngSwitchCase="0">ثبت شده</div>
                        <div *ngSwitchCase="1">تایید شده توسط پزشک</div>
                        <div *ngSwitchCase="2">جاری</div>
                        <div *ngSwitchCase="3">انجام شده</div>
                        <div *ngSwitchCase="4">رد شده</div>
                        <!--                    <div *ngSwitchCase="5">اتمام مشاوره توسط بیمار</div>-->
                        <!--                    <div *ngSwitchCase="6">تسویه حساب</div>-->
                        <!--                    <div *ngSwitchCase="7">مشاوره حضوری</div>-->
                    </div>
                </div>
                <div class="mx-1 text-black-50 bold">
                    <span>دوره: </span>
                    <span>{{ doctorSettlement?.title }}</span>
                </div>
                <div class="text-black-50">
                    <span>شماره: </span>
                    <span>{{ doctorSettlement?.refNumber | enNum }}</span>
                </div>
            </div>

            <table class="border w-100 overflow-auto bg-gray" >
                <tbody>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">نام پزشک</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.firstName }} {{ doctorSettlement?.lastName }}
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">کد ملی</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.nationalCode ? (doctorSettlement?.nationalCode | enNum) : '-' }}
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">تاریخ واریز</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.paidAt ? (doctorSettlement?.paidAt | date | persianDate) : '-' }}
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">مبلغ به عدد</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.price | number | enNum}} ريال
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">مبلغ به حروف</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.price | numberToWords }}ريال
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">شماره کارت</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.cardNumber ? (doctorSettlement?.cardNumber | enNum) : '-' }}
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">شماره شبا</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        {{ doctorSettlement?.ibanNumber ? (doctorSettlement?.ibanNumber | enNum) : '-' }}
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">سهم پزشک</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
                        % {{ doctorSettlement?.contributionValue ? (doctorSettlement?.contributionValue | enNum) : '-' }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="mx-3 pb-4 align-self-end d-flex">
        <ng-container *ngIf="!buttons || !buttons.length">
            <button class="btn-red-link btn-sm d-flex align-items-center" (click)="cancel()">بستن</button>
        </ng-container>
        <ng-container *ngIf="!!buttons && !!buttons.length">
            <div *ngFor="let btn of buttons">
                <button *ngIf="btn.type == 'confirm'" type="button" class="d-flex align-items-center btn btn-sm" [class]="btn.class" (click)="confirm()">
                    <img [src]="btn.icon" alt="arrow-left">
                    <span class="px-1">{{ btn.text }}</span>
                </button>
                <button
                        id="ngx-print-button"
                        [useExistingCss]="true"
                        styleSheetFile="./details-dialog.component.css"
                        ngxPrint
                        printSectionId="print-section"
                        style="display: none;">
                    Print
                </button>
                <button *ngIf="btn.type == 'cancel'" type="button" class="btn-sm btn d-flex align-items-center btn-red-link" [class]="btn.class" (click)="cancel()">
                    <i class="fa fa-close"></i>
                    <span class="px-1">{{ btn.text }}</span>
                </button>
            </div>
        </ng-container>
    </div>
</div>
