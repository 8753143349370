import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-table-pdf',
  templateUrl: './table-pdf.component.html',
  styleUrls: ['./table-pdf.component.css']
})
export class TablePdfComponent implements OnInit {
  @Input() titles: { key: string; label: string }[] = [];
  @Input() data: Record<string, any>[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
