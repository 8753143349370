import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.css']
})
export class SidebarFilterComponent implements OnInit {
  form: FormGroup;

  @Input() initialValues: { [key: string]: any } = {}; // Input for initial values
  @Input() formFields: { [key: string]: any }[] = [];  // Input for form field configurations

  @Output() changeFilter = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.createForm(); // Initialize the form after inputs are set
    this.updateFormValues(); // Update initial values
  }

  createForm(): FormGroup {
    const formGroup: { [key: string]: FormControl } = {};
    this.formFields.forEach(field => {
      formGroup[field.name] = new FormControl(
          this.initialValues[field.name] || '', // Set initial value if available
          field.validators || [] // Apply validators if provided
      );
    });
    return new FormGroup(formGroup);
  }

  updateFormValues(): void {
    Object.keys(this.initialValues).forEach(key => {
      if (this.form.controls[key]) {
        this.form.controls[key].setValue(this.initialValues[key]);
      }
    });
  }

  onDateChange(value: string, fieldName: string): void {
    // Update the form control value when a date changes
    this.form.get(fieldName)?.setValue(value);
    console.log(`Field '${fieldName}' updated to:`, value);
  }

  onSubmit(): void {
    const filteredValues = this.form.value; // Get form values
    // Filter out null, undefined, or empty string values before emitting
    const filteredData = Object.keys(filteredValues)
        .filter(key => this.isNotEmpty(filteredValues[key]))
        .reduce((acc, key) => ({ ...acc, [key]: filteredValues[key] }), {});

    this.changeFilter.emit(filteredData);
  }

  isNotEmpty(value: any): boolean {
    return value !== null && value !== undefined && value !== ''; // Consistent isEmpty check
  }

  resetForm(): void {
    this.form.reset(); // Reset the form to initialValues
    this.resetFilter.emit(true);
  }
}
