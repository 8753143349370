import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  private ones: string[] = [
    '', 'یک', 'دو', 'سه', 'چهار', 'پنج', 'شش', 'هفت', 'هشت', 'نه'
  ];

  private teens: string[] = [
    'ده', 'یازده', 'دوازده', 'سیزده', 'چهارده', 'پانزده', 'شانزده', 'هفده', 'هجده', 'نوزده'
  ];

  private tens: string[] = [
    '', '', 'بیست', 'سی', 'چهل', 'پنجاه', 'شصت', 'هفتاد', 'هشتاد', 'نود'
  ];

  private hundreds: string[] = [
    '', 'صد', 'دویست', 'سیصد', 'چهارصد', 'پانصد', 'ششصد', 'هفتصد', 'هشتصد', 'نهصد'
  ];

  private thousands: string[] = [
    '', 'هزار', 'میلیون', 'میلیارد'
  ];

  transform(value: number | string): string {
    if (value === 0) return 'صفر';
    return this.convertNumberToWords(value);
  }

  private convertNumberToWords(value: number | string): string {
    let word = '';
    let level = 0;
    while (value > 0) {
      let part = +value % 1000;
      if (part > 0) {
        let partWord = this.convertHundreds(part);
        word = partWord + (this.thousands[level] ? ' ' + this.thousands[level] : '') + ' ' + (word ? ' و ' + word : '');
      }
      value = Math.floor(+value / 1000);
      level++;
    }

    return word.trim();
  }

  private convertHundreds(num: number | string): string {
    let word = '';

    if (num >= 100) {
      word += this.hundreds[Math.floor(+num / 100)] + ' ';
      num = +num % 100;
    }

    if (num >= 10 && num <= 19) {
      word += (word ? 'و ' : '') +  this.teens[+num - 10] + ' ';
    } else if (num >= 20) {
      word += (word ? 'و ' : '') + this.tens[Math.floor(+num / 10)] + ' ';
      num = +num % 10;
    }

    if (num > 0 && num < 10) {
      word += (word ? 'و ' : '') + this.ones[num] + ' ';
    }

    return word.trim();
  }

}
