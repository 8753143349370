import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'consultantState'
})
export class ConsultantStatePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 0:
        return 'نامشخص';
      case 1:
        return 'شروع نشده';
      case 2:
        return 'در حال برگزاری';
      case 3:
        return 'اتمام مشاوره';
      case 4:
        return 'اتمام مشاوره و انتظار برای ارسال مدارک توسط بیمار';
      case 5:
        return 'اتمام مشاوره و تکمیل اطلاعات توسط پزشک در فرصتی دیگر';
      case 6:
        return 'مشاوره بسته شده توسط پزشک';
      case 7:
        return 'مشاوره بسته شده توسط مدیر یا سیستمی';
      case 8:
        return 'لغو شده';
      case 9:
        return 'منقضی شده';
      default:
        return 'نامشخص';
    }
  }
}
