import { Pipe, PipeTransform } from '@angular/core';
import {
  ConsultantTypeEnum
} from "../finance/doctor-finance-management/doctor-finance-transactions/doctor-finance-transactions-model";
 // مسیر Enum خود را مشخص کنید.

@Pipe({
  name: 'consultantType'
})
export class ConsultantTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case ConsultantTypeEnum.Inperson:
        return 'مشاوره حضوری';
      case ConsultantTypeEnum.VideoCall:
        return 'مشاوره تصویری';
      case ConsultantTypeEnum.VoiceCall:
        return 'مشاوره صوتی';
      case ConsultantTypeEnum.TextChat:
        return 'چت متنی';
      default:
        return 'نامشخص';
    }
  }
}
