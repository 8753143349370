import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionType'
})
export class TransactionTypePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'دریافت';
      case 1:
        return 'پیش دریافت';
      case 2:
        return 'پرداخت';
      default:
        return 'نامشخص';
    }
  }

}
