import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-reusable-list',
  templateUrl: './reusable-list.component.html',
  styleUrls: ['./reusable-list.component.css']
})
export class ReusableListComponent implements OnInit , OnChanges {
  @Input() data!: { [key: string]: any }; // Input object
  @Input() title!: string; // Title of the form

  rows: { key: string; value: any }[][] = []; // Rows of key-value pairs

  ngOnChanges(): void {
    if (this.data) {
      const entries = Object.entries(this.data).map(([key, value]) => ({ key, value }));
      this.rows = [];
      for (let i = 0; i < entries.length; i += 2) {
        this.rows.push(entries.slice(i, i + 2)); // Group into rows of 2
      }
    }
  }

  // Utility function to check if a value is an array
  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  // Utility function to check if a value is an object
  isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  // Utility function to get object keys
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
    constructor() { }

  ngOnInit(): void {
  }

}
