<div class="factor">
    <div class="factor__title">شرکت کمک‌رسان ایران(SOS)</div>
    <div class="factor__content text-sm">
        <div class="factor__content-header">
            <div class="factor__content-header-text">رسید نوبت</div>
            <div class="factor__content-header-text">شماره: {{pdfData.invoiceNumber }}</div>
        </div>
        <div class="factor__content-table ">
            <div class="factor__rows">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark border-bottom  ">نام بیمار</div>
                <div class="py-1 px-1 w-50 border-bottom border-2 border-dark">{{pdfData.patientFirstName}} {{pdfData.patientLastName}}</div>
            </div>
            <div class="factor__rows">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark border-bottom">تاریخ نوبت</div>
                <div class="py-1 px-1 w-50 border-bottom border-2 border-dark">{{ pdfData.scheduleAt | date | enNum}}</div>
            </div>
            <div class="factor__rows">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark border-bottom">زمان نوبت</div>
                <div class="py-1 px-1 w-50  border-bottom border-2 border-dark">{{ pdfData.scheduleAt | date:'HH:mm' | enNum }}
                </div>
            </div>
            <div class="factor__rows">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark border-bottom">نوع مشاوره</div>
                <div class="py-1 px-1 w-50  border-bottom border-2 border-dark">{{pdfData.consultantTypeId | consultantType}}</div>
            </div>
            <div class="factor__rows">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark border-bottom">مبلغ مشاوره</div>
                <div class="py-1 px-1 w-50  border-bottom border-2 border-dark">{{ pdfData.price | number | enNum}}</div>
            </div>
            <div class="factor__rows ">
                <div class="py-1 px-1 w-50 border-left border-2 border-dark">سهم پزشک</div>
                <div class="py-1 px-1 w-50  ">{{ pdfData.doctorContributionPrice | number | enNum}}</div>
            </div>
        </div>
        <div class="pt-5">
            <div class="">
                <span class="py-1 px-1 w-50  "> نام پزشک</span>
                <span class="py-1 px-1 w-50  ">{{ pdfData.doctorFirstName}} {{pdfData.doctorLastName}}</span>
            </div>
            <div class="">
                <span class="py-1 px-1 w-50  "> شماره تماس </span>
                <span class="py-1 px-1 w-50  ">{{ pdfData.doctorMobileNumber | enNum}}</span>
            </div>
        </div>
    </div>
</div>
