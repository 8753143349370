import {Component, Input, OnInit} from '@angular/core';
import {
  DoctorTransactionPdfModel
} from '../../../finance/doctor-finance-management/doctor-finance-transactions/doctor-finance-transactions-model';

@Component({
  selector: 'app-factor-pdf',
  templateUrl: './factor-pdf.component.html',
  styleUrls: ['./factor-pdf.component.css']
})
export class FactorPdfComponent implements OnInit {
  @Input() pdfData: DoctorTransactionPdfModel;

  constructor() { }

  ngOnInit(): void {
  }

}
