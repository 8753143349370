<div class="overflow-x-auto">
    <table class="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg">
         <thead class="bg-gray-50">
        <tr>
            <th *ngFor="let title of titles" class="px-6 py-3 text-left text-sm font-medium text-gray-500">
                {{ title.label }}
            </th>
        </tr>
        </thead>

        <!-- Table Body -->
        <tbody class="divide-y divide-gray-200">
        <tr *ngFor="let row of data">
            <td *ngFor="let title of titles" class="px-6 py-4 text-sm text-gray-700">
                {{ row[title.key] }}
            </td>
        </tr>
        </tbody>
    </table>
</div>
