import { JALALI_MOMENT_FORMAT } from './jalali-moment-format'
import { JalaliMomentDateAdapter } from './jalali-moment-date-adapter';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConsultantTypePipe } from './consultant-type.pipe';

@NgModule({
    exports: [
        MatDatepickerModule,
        MatDialogModule,
        ConsultantTypePipe
    ],
    providers: [
        { provide: DateAdapter, useClass: JalaliMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: JALALI_MOMENT_FORMAT },
      ],
    declarations: [
      ConsultantTypePipe
    ]
})

export class CoreModule {}