
export interface DoctorFinanceTransactionsModel {
    resultCount: number;
    fetchTime: Date;
    resultItems: ResultItemDoctorFinanceTransactionsModel[];
}

export interface ResultItemDoctorFinanceTransactionsModel {
    id: number;
    globalUserId: string;
    price: number;
    status: number;
    ibanNumber: string;
    cardNumber: string;
    refNumber: string;
    paidAt: string;
    description: string;
    createdAt: string;
    lastModifiedAt: string;
    startPeriodAt: string;
    endPeriodAt: string;
    title: string;
    doctorSpecialtyName: string;
    canConfirm: boolean;
    isSosDoctor: boolean;
    periodType: number;
    contributionValue: number;
    insuranceDeductionPercentage: number;
    taxDeductionPercentage: number;
    insuranceDeductionPrice: number;
    taxDeductionPrice: number;
    adminApproved: boolean;
    doctorApproved: boolean;
    monthlyReceiptPrice: number;
    invoiceSerial: number;
    invoiceDate: string;
    firstName: string;
    lastName: string;
    nationalCode: string;
}



export interface ConsultantDoctorTransactionDetailsModel {
    id: number;
    status: number;
    price: number;
    scheduleAt: string; // ISO Date Format
    scheduleId: number;
    consultantId: number;
    priceAfterCancellation: number;
    cancelType: number;
    cancellationDate: string; // ISO Date Format
    cancellationDescription: string;
    consultantTypeId: number;
    isSos: boolean;
    doctorInsuranceDeductionPercent: number;
    doctorTaxDeductionPercent: number;
    insuranceDeductionPrice: number;
    taxDeductionPrice: number;
    contributionValue: number;
}

export interface DoctorTransactionDetailsModel {
    userGlobalId: string;
    firstName: string;
    lastName: string;
    nationalCode: string;
    RankName: string;
    doctorSettlementPeriodType: number;
    price: number;
    monthlyReceiptPrice: number;
    consultants: ConsultantDoctorTransactionDetailsModel[];
}

export interface DoctorTransactionPdfModel {
    patientFirstName: string;
    patientLastName: string;
    doctorFirstName: string;
    doctorLastName: string;
    doctorMobileNumber: string;
    invoiceNumber: number;
    price: number;
    scheduleAt: string; // ISO 8601 date string
    consultantTypeId: number;
    doctorContributionPrice: number;
}
export enum ConsultantTypeEnum {
    Inperson = 1,
    VideoCall = 2,
    VoiceCall = 3,
    TextChat = 4,
}
