import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'شارژ کیف پول توسط بیمار از درگاه پرداخت';
      case 1:
        return 'عودت وجه بیمار';
      case 2:
        return 'مشاوره جدید';
      case 3:
        return 'مشاوره حضوری جدید';
      case 4:
        return 'اتمام مشاوره توسط پزشک';
      case 5:
        return 'صورتحساب پزشک';
      case 6:
        return 'شارژ کیف پول بیمار توسط ادمین';
      case 7:
        return 'تسوبه حساب سازمان از درگاه پرداخت';
      case 8:
        return 'لغو مشاوره توسط بیمار';
      case 9:
        return 'لغو مشاوره به دلیل غیبت بیمار';
      case 10:
        return 'لغو مشاوره توسط پزشک ';
      default:
        return 'نامشخص';
    }
  }

}
